import React from "react"
import buttonStyles from "./button.module.scss"
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { Link } from "gatsby"
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons'

library.add(faPlayCircle)

export default props => {
  if (props.type==="a") {
    return (
      <a 
        href={props.href} 
        className={`${buttonStyles.button} ${buttonStyles.isRounded} ${buttonStyles[props.styling]} ${props.linkOut} button`} 
        onClick={props.onClick}
        target={props.target || ''}
        rel={(props.external ? 'noopener noreferrer' : '')}>
          {(props.icon ? <FontAwesomeIcon style={({'marginRight':`0.3em`, 'fontSize': `16px`})} icon={props.icon} /> : '' )}
          {props.text}
        </a>
    )
  } else if (props.type==="anchorlink") {
    return (
      <AnchorLink 
        href={props.href} 
        className={`${buttonStyles.button} ${buttonStyles.isRounded} ${buttonStyles[props.styling]} button`} 
        onClick={props.onClick}>{props.text}</AnchorLink>
    )
  } else if (props.type==="link") {
    return (
      <Link 
        to={props.href} 
        className={`${buttonStyles.button} ${buttonStyles.isRounded} ${buttonStyles[props.styling]} button`} 
        onClick={props.onClick}>{props.text}</Link>
    )
  } else {
    return (
      <button 
        href={props.href} 
        className={`${buttonStyles.button} ${buttonStyles.isRounded} ${buttonStyles[props.styling]} button`} 
        onClick={props.onClick}
        style={({'fontSize': `16px`})}>
        {(props.icon ? <FontAwesomeIcon style={({'marginRight':`0.3em`, 'fontSize': `16px`})} icon={props.icon} /> : '' )}
        {props.text}
      </button>
    )
  }
}